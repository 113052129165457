import Axios from "../AxiosInstance"
import GetAuthToken from "../Utils/AuthToken"

const GetAllProductsAPI = async () => {
    let resolve = {
        data: null,
        error: null
    }
    try {
        let res = await Axios({
            url: "/products",
            method: "GET",
            headers: GetAuthToken()
        })
        resolve.data = res.data
    } catch (err) {
        if (err && err?.response) {
            resolve.error = err?.response?.message || err?.response?.data?.message
        } else {
            resolve.error = "SomthingWent wrong"
        }
    }
    return resolve
}

const AddProductAPI = async (data) => {
    let resolve = {
        data: null,
        error: null
    }
    try {
        let res = await Axios({
            url: "/products",
            method: "POST",
            data: data,
            headers: GetAuthToken()
        })
        resolve.data = res.data
    } catch (err) {
        if (err && err?.response) {
            resolve.error = err?.response?.message || err?.response?.data?.message
        } else {
            resolve.error = "SomthingWent wrong"
        }
    }
    return resolve
}

const SavePublishReadyProductAPI = async (data) => {
    let resolve = {
        data: null,
        error: null
    }
    try {
        let res = await Axios({
            url: "/products/saveproduct",
            method: "POST",
            data: data,
            headers: GetAuthToken()
        })
        resolve.data = res.data
    } catch (err) {
        if (err && err?.response) {
            resolve.error = err?.response?.message || err?.response?.data?.message
        } else {
            resolve.error = "SomthingWent wrong"
        }
    }
    return resolve
}

const DeleteProductAPI = async (id) => {
    let resolve = {
        data: null,
        error: null
    }
    try {
        let res = await Axios({
            url: `/products/?id=${id}`,
            method: "DELETE",
            headers: GetAuthToken()
        })
        resolve.data = res.data
    } catch (err) {
        if (err && err?.response) {
            resolve.error = err?.response?.message || err?.response?.data?.message
        } else {
            resolve.error = "SomthingWent wrong"
        }
    }
    return resolve
}


export { GetAllProductsAPI, AddProductAPI, DeleteProductAPI, SavePublishReadyProductAPI }