import React, { useEffect, useState } from 'react'

// ANT-D :
import { Button, Upload } from 'antd'
import ImgCrop from 'antd-img-crop';

// Redux :
import { useSelector } from 'react-redux';
// Helper :
import GenImgUrl from '../Utils/GenImgUrl'

// CSS :
import './Style/EditProfile.scss'
import './Style/imgupload.css';
import { UpdateProfileAPI } from '../Api/auth';
import toast from 'react-hot-toast';





function EditProfile() {
  let UserData = useSelector(state => state.userData)

  const [formData, setFormData] = useState({
    displayName: UserData?.displayName || "",
    email: UserData?.email || "",
    address: UserData?.address || "",
    details: UserData?.details || "",
    taxInformation: UserData?.taxInformation || "",
    stripeSecretKey: UserData?.stripeSecretKey || "",
    stripePrimaryKey: UserData?.stripePrimaryKey || "",
    facebook: UserData?.facebook || "",
    instagram: UserData?.instagram || "",
    tiktok: UserData?.tiktok || "",
    youtube: UserData?.youtube || "",
    profileImage: UserData?.profileImage || null
  })
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false)
  const [reload, setReload] = useState(false)


  const enteringFormData = (event) => {
    let { name, value } = event.target;

    setFormData({
      ...formData,
      [name]: value
    })
  }

  const enteringFile = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  }
  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const imgWindow = window.open(src);
    imgWindow?.document.write(`<img src="${src}" alt="preview" />`);
  };

  const UpdateProfile = async () => {
    setLoading(true)
    let Payload = new FormData()
    Object.keys(formData).map(key => {
      Payload.append(key, formData[key])
    })
    if (fileList && fileList.length >= 1) {
      Payload.append("file", fileList[0]?.originFileObj)
    }
    let res = await UpdateProfileAPI(Payload)
    if (res.error != null) {
      toast.error(res.error)
    } else {
      toast.success(res.data.message)
      setTimeout(() => {
        // window.location.href = "/"
      }, 1000);
    }
    setLoading(false)
  }


  useEffect(() => {
    setFormData({
      displayName: UserData?.displayName || "",
      email: UserData?.email || "",
      address: UserData?.address || "",
      details: UserData?.details || "",
      taxInformation: UserData?.taxInformation || "",
      stripeSecretKey: UserData?.stripeSecretKey || "",
      stripePrimaryKey: UserData?.stripePrimaryKey || "",
      facebook: UserData?.facebook || "",
      instagram: UserData?.instagram || "",
      tiktok: UserData?.tiktok || "",
      youtube: UserData?.youtube || "",
      profileImage: UserData?.profileImage || null

    })
  }, [UserData])


  return (
    <div className='editprofile-container'>

      <div className="title">
        <h2 className="inline-block bg-gradient-to-r from-[#46A0E3] to-[#5845DF] bg-clip-text p-2 text-6xl font-extrabold text-transparent">
          Edit Profile
        </h2>
      </div>

      <div className='flex flex-col gap-8 bg-white p-6 rounded-2xl max-w-[600px] m-auto'>
        <div className="m-auto">
          {/* <ImgUploader  /> */}
          <ImgCrop rotationSlider>
            <Upload
              listType="picture-card"
              fileList={fileList}
              multiple={false}
              onChange={enteringFile}
              onPreview={onPreview}
              className="custom-upload" // Apply the custom class
            >
              {
                formData?.displayName ?
                  <img src={GenImgUrl(formData?.profileImage)} alt="" style={{ width: "98%", height: "98%", objectFit: "cover", borderRadius: ".4rem" }} />
                  :
                  fileList.length < 1 && '+ Upload'
              }
            </Upload>
          </ImgCrop>
        </div>

        <div className='flex flex-col gap-2'>
          <div className="flex gap-2 items-center">
            <div className="flex flex-col gap-1 w-full">
              <p className='ml-3'> Name </p>
              <input
                className="input-field mt-4 w-full rounded-2xl border-0 px-3 py-4"
                id="name"
                type="name"
                name='displayName'
                value={formData?.displayName}
                onChange={enteringFormData}
                placeholder="Name"
              />
            </div>
            <div className="flex flex-col gap-1 w-full">
              <p className='ml-3'>Email </p>
              <input
                className="input-field mt-4 w-full rounded-2xl border-0 px-3 py-4"
                id="name"
                type="text"
                name='email'
                value={formData?.email}
                onChange={enteringFormData}
                placeholder="Email"
              />
            </div>
          </div>

          <div className="flex gap-2 items-center">
            <div className="flex flex-col gap-1 w-full">
              <p className='ml-3'>Address </p>
              <input
                className="input-field mt-4 w-full rounded-2xl border-0 px-3 py-4"
                id="name"
                type="text"
                name='address'
                value={formData?.address}
                onChange={enteringFormData}
                placeholder="Address"
              />
            </div>
            <div className="flex flex-col gap-1 w-full">
              <p className='ml-3'> Tax Information </p>
              <input
                className="input-field mt-4 w-full rounded-2xl border-0 px-3 py-4"
                id="name"
                type="name"
                name='taxInformation'
                value={formData?.taxInformation}
                onChange={enteringFormData}
                placeholder="Tax Information"
              />
            </div>
          </div>

          <div className="flex gap-2 items-center">
            <div className="flex flex-col gap-1 w-full">
              <p className='ml-3'> Detail </p>
              <input
                className="input-field mt-4 w-full rounded-2xl border-0 px-3 py-4"
                id="name"
                type="name"
                name='details'
                value={formData?.details}
                onChange={enteringFormData}
                placeholder="Details"
              />
            </div>
          </div>

          <div className="flex gap-2 items-center">
            <div className="flex flex-col gap-1 w-full">
              <p className='ml-3'> Strip Secret Key </p>
              <input
                className="input-field mt-4 w-full rounded-2xl border-0 px-3 py-4"
                id="name"
                type="name"
                name='stripeSecretKey'
                value={formData?.stripeSecretKey}
                onChange={enteringFormData}
                placeholder="Secret Key"
              />
            </div>
            <div className="flex flex-col gap-1 w-full">
              <p className='ml-3'> Strip Primary Key </p>
              <input
                className="input-field mt-4 w-full rounded-2xl border-0 px-3 py-4"
                id="name"
                type="text"
                name='stripePrimaryKey'
                value={formData?.stripePrimaryKey}
                onChange={enteringFormData}
                placeholder="Primary Key"
              />
            </div>
          </div>

          <div className="flex gap-2 items-center">
            <div className="flex flex-col gap-1 w-full">
              <p className='ml-3'> Facebook </p>
              <input
                className="input-field mt-4 w-full rounded-2xl border-0 px-3 py-4"
                id="name"
                type="name"
                name='facebook'
                value={formData?.facebook}
                onChange={enteringFormData}
                placeholder="Facebook Link"
              />
            </div>
            <div className="flex flex-col gap-1 w-full">
              <p className='ml-3'> Instagram </p>
              <input
                className="input-field mt-4 w-full rounded-2xl border-0 px-3 py-4"
                id="name"
                type="text"
                name='instagram'
                value={formData?.instagram}
                onChange={enteringFormData}
                placeholder="Instagram Link"
              />
            </div>
          </div>

          <div className="flex gap-2 items-center">
            <div className="flex flex-col gap-1 w-full">
              <p className='ml-3'> TikTok </p>
              <input
                className="input-field mt-4 w-full rounded-2xl border-0 px-3 py-4"
                id="name"
                type="name"
                name='tiktok'
                value={formData?.tiktok}
                onChange={enteringFormData}
                placeholder="TikTok Link"
              />
            </div>
            <div className="flex flex-col gap-1 w-full">
              <p className='ml-3'> Youtube </p>
              <input
                className="input-field mt-4 w-full rounded-2xl border-0 px-3 py-4"
                id="name"
                type="text"
                name='youtube'
                value={formData?.youtube}
                onChange={enteringFormData}
                placeholder="Youtube Link"
              />
            </div>
          </div>

        </div>

        <div className='btnn flex gap-2'>
          <Button
            loading={loading}
            type="submit"
            onClick={UpdateProfile}
            className="mx-auto mt-4 flex w-full items-center justify-center gap-2 rounded-full bg-[#1469F3] px-4 py-2 font-medium text-white hover:opacity-80 h-[40px]">

            <label htmlFor="">Update</label>
          </Button>

          <Button
            type="submit"
            className="mx-auto mt-4 flex w-full items-center justify-center gap-2 rounded-full bg-[#f36464d9] px-4 py-2 font-medium text-white hover:opacity-80 h-[40px]">
            <label htmlFor="">Cancel</label>
          </Button>
        </div>
      </div>
    </div >
  )
}

export default EditProfile