import React from 'react';

//css
import './Style/AboutUs.scss';

const AboutUs = () => {
  return (
    <div className="aboutus">
      <div className="aboutus-container">
        <h1 className='inline-block bg-gradient-to-r from-[#46A0E3] to-[#5845DF] bg-clip-text p-2 text-6xl font-extrabold text-transparent'>Terms & Conditions</h1>
        <p>
          Empower your dropshipping business. Source from Alibaba, Tmall, AliExpress, and 1688 in one click. Connect your store, sell on multiple marketplaces, and manage everything from one dashboard. One-click multichannel selling, simplified.Terms of Service</p>
        <h3>Definitions</h3>
        <ul>
          <li> <b> We, us, or our </b> shall refer to Plugspace.io.</li>
          <li><b> You, your, user, or customer </b> shall refer to any individual or entity who accepts this Agreement or purchases our Services.</li>
          <li> <b>Services </b> refers to the Plugspace.io website, software, application, plug-in, component, functionality, or program provided by Plugspace.io.</li>
        </ul>
        <h3>Acceptance of Terms</h3>
        <p>By using Plugspace.io Services, you signify that you have read, understood, and agree to be legally bound by these Terms. Violation of any of the terms below will result in the termination of your account.  </p>

        <h3>Your Registration Obligations</h3>
        <p>To access and use our Services, you will be required to create an account on our Site. When registering, you represent and warrant that all information you submit is accurate, current, and complete, and you will keep this information updated. You are responsible for maintaining the security of your account and password. You are solely responsible for all activities that occur on your account.</p>

        <h3>Modification of Terms, Site, or Services</h3>
        <p>Plugspace.io reserves the right to modify these Terms at any time. We will post any changes on this page, and if the changes are significant, we will provide a prominent notice. Your continued use of the Services following such modifications constitutes your consent to the modified Terms.</p>

        <h3>Availability of Website/Services</h3>
        <p> We will make reasonable efforts to ensure our Services are available 24/7. However, you acknowledge that there may be times when our Site/Services are inaccessible due to equipment malfunctions, maintenance, repairs, or circumstances beyond our control. </p>

        <h3>Fees and Payment; Automatic Renewal</h3>
        <ul>
          <li>Your charges and fees are outlined in your order documentation.</li>
          <li>Plugspace.io may change prices and fees at any time, and these will be posted online.</li>
          <li>To ensure continued service, we offer auto-renewing subscriptions. You will be automatically charged for the Services based on the payment option and duration you selected during subscription, regardless of your usage of the Services.</li>
          <li>You can cancel anytime before the renewal date to avoid future charges.</li>
        </ul>

        <h3>Limitation of Liability and Disclaimer of Representation and Warranties</h3>
        <ul>
          <li>The Services are available "AS-IS." Plugspace.io makes no warranties or representations and disclaims all liability for the accuracy, completeness, availability, timeliness, security, or reliability of the Services or any content.</li>
          <li>In no event shall Plugspace.io be liable to you for any damages or loss of profits.</li>
        </ul>

        <h3>Disclaimer of Warranties Regarding Product Quality</h3>
        <ul>
          <li>WE DO NOT GUARANTEE THE QUALITY OF ANY PRODUCTS SOURCED THROUGH OUR SERVICES. Plugspace.io is a platform that offers dropshipping and does not act as the seller of any products. We rely on the information provided by buyers and sellers and cannot verify the quality of their products. Only place orders after your due diligence and confirmation of product quality and condition with the seller. Please refer to the seller's terms and conditions for information on returns and refunds.</li>
        </ul>

        <h3> User Conduct</h3>
        <p>You agree to use the Service in a lawful manner and in accordance with these Terms. You agree not to:</p>
        <ul>
          <li>Transmit any harmful, offensive, or threatening content.</li>
          <li>Interfere with or disrupt the Service.</li>
        </ul>

        <h3> Termination </h3>
        <p>Plugspace.io may terminate your access to the Service at any time, for any reason, with or without notice.</p>

        <h3> Intellectual Property </h3>
        <p>The Service and all content included on the Service, such as text, graphics, logos, images, and software, are the property of Plugspace.io or its licensors and are protected by copyright, trademark, and other intellectual property laws.</p>

        <h3> Governing Law </h3>
        <p>These Terms shall be governed by and construed in accordance with the laws of the State of Texas, United States of America.  </p>

        <h3> Dispute Resolution </h3>
        <p>Any dispute arising out of or relating to these Terms shall be resolved by binding arbitration in accordance with the rules of the American Arbitration Association. The arbitration shall be held in Dallas, Texas.  </p>

        <h3> Force Majeure </h3>
        <p>Plugspace.io shall not be liable for any delay or failure to perform its obligations under these Terms if such delay or failure is due to causes beyond its reasonable control, including but not limited to acts of God, war, terrorism, strikes, or governmental actions.  </p>

        <h3> Indemnification </h3>
        <p>You agree to indemnify and hold Plugspace.io, its affiliates, officers, directors, employees, and agents harmless from any and all claims, damages, losses, liabilities, costs, and expenses (including reasonable attorneys' fees) arising out of or relating to your use of the Services, your violation of these Terms, or your violation of any rights of a third party.  </p>

        <h3> Data Privacy </h3>
        <p>Please refer to our Privacy Policy for information on how we collect, use, store, and share your data.</p>

        <h3> Third-Party Links </h3>
        <p>The Services may contain links to third-party websites or resources. You acknowledge and agree that Plugspace.io is not responsible for the availability or accuracy of such websites or resources, or for the content, products, or services available from such websites or resources.</p>

        <h3> Severability </h3>
        <p>If any provision of these Terms is found to be invalid or unenforceable, that provision will be removed or modified to the minimum extent necessary, and the remaining provisions will remain in full force and effect.</p>

        <h3> Assignment </h3>
        <p>Plugspace.io may assign its rights and obligations under these Terms to a third party without your consent.</p>

        <h3> Entire Agreement </h3>
        <p>These Terms constitute the entire agreement between you and Plugspace.io and supersede any prior or contemporaneous agreements or understandings, whether written or oral.</p>

        <h3> License Grant </h3>
        <p>Plugspace.io grants you a limited, non-exclusive, non-transferable license to use the Services in accordance with these Terms.</p>

        <h3> Contact Us </h3>
        <p>If you have any questions about these Terms, please contact us at <strong> support@plugspace.io. </strong></p>

      </div>
    </div>
  );
};

export default AboutUs;
