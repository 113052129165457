import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Assets | ICONS :
import { FaArrowRight } from 'react-icons/fa';
import { AiOutlineUserAdd } from 'react-icons/ai';

// APIs :
import { RegisterAPI } from "../Api/auth"
// Helpers :
import toast from 'react-hot-toast';
import { Button } from 'antd';





const SignupForm = () => {
  const Navigate = useNavigate()

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    displayName: "",
  })
  const [loading, setLoading] = useState(false)

  const enteringFormData = (event) => {
    let { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    })
  }


  const onSubmit = async (event) => {
    event?.preventDefault();
    setLoading(true)

    const { displayName, email, password } = formData;

    let res = await RegisterAPI({ email, password, displayName })
    if (res.error != null) {
      toast.error(res.error)
    } else {
      toast.success(res.data?.message)
      setTimeout(() => {
        Navigate("/login")
      }, 2000);
    }
    setLoading(false)
  };

  return (
    <>
      <div className="header mb-2 p-2 text-center text-blue-400">
        <AiOutlineUserAdd size={50} className="icon" />
        <div>
          <p className="title p-2 text-lg font-bold">
            Register with your email
          </p>
        </div>
      </div>
      <form onSubmit={onSubmit} className="">
        <div className="mt-4 flex gap-3 flex-col">
          <div className='phone_signin'>
            <input
              className="w-full rounded-full px-12 py-2 shadow-sm bg-[#EFF6FB] border-none"
              id="displayName"
              type="text"
              name='displayName'
              placeholder="Name"
              onChange={enteringFormData}
            />
          </div>
          <div>
            <input
              className="w-full rounded-full px-12 py-2 shadow-sm bg-[#EFF6FB] border-none"
              id="email"
              type="email"
              placeholder="Email"
              name='email'
              onChange={enteringFormData}
            />
          </div>
          <div>
            <input
              className="w-full rounded-full px-12 py-2 shadow-sm bg-[#EFF6FB] border-none"
              id="password"
              type="password"
              placeholder="Password"
              name='password'
              onChange={enteringFormData}
            />
          </div>
          <Button
            loading={loading}
            className="h-[40px] mx-auto mt-4 flex w-full items-center justify-center gap-2 rounded-full bg-[#1469F3] px-4 py-2 font-medium text-white hover:opacity-80"
            onClick={onSubmit}
            type="submit">
            Sign Up
            <FaArrowRight className="icon" />
          </Button>
          <p className="signin-link flex justify-center gap-1 mt-2 text-center text-gray-700">
            Already have an account?{' '}
            <p className="font-bold text-blue-500 cursor-pointer" onClick={() => Navigate("/login")}>
              Sign In
            </p>
          </p>
        </div>
      </form>
    </>
  );
}

export default SignupForm;
