import React, { useState } from 'react'

// Components :
import ListProducts from "./ProductsList/Index"
import EditProduct from "./EditProduct/Index"





const Index = () => {
    const [currentPage, setCurrentPage] = useState("list")
    const [selectedId, setSelectedId] = useState(null)

    const closePage = () => {
        setCurrentPage("list")
        setSelectedId(null)
    }

    return (
        <>
            {
                currentPage == "list" ?
                    <ListProducts setCurrentPage={setCurrentPage} setSelectedId={setSelectedId} />
                    :
                    <EditProduct closePage={closePage} selectedId={selectedId} />
            }
        </>
    )
}

export default Index