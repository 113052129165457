import React from 'react'
import { useNavigate } from 'react-router-dom';

// Assets | ICONS :
import { PiTiktokLogo } from "react-icons/pi";

import { useSelector } from 'react-redux';
import ImgURL from "../Utils/GenImgUrl"

// CSS :
import './Style/NavBar.scss'
import { Popover } from 'antd';
import { DashOutlined, LogoutOutlined, ProfileOutlined } from '@ant-design/icons';





function NavBar() {
    const Navigate = useNavigate();

    let UserData = useSelector(state => state?.userData)

    let LogOut = () => {
        localStorage.clear()
        setTimeout(() => {
            window.location.href = "/"
        }, 1000);
    }

    const content = (
        <div className='antPopover'>
            <div className='popoverItem flex gap-2' onClick={() => Navigate("/profile")}>
                <ProfileOutlined className='icon' />
                Profile
            </div>
            <div className='popoverItem flex gap-2' onClick={() => Navigate("/dashboard")}>
                <DashOutlined className='icon' />
                Dashboard
            </div>
            <div className='popoverItem flex gap-2' onClick={LogOut}>
                <LogoutOutlined className='icon' />
                Logout
            </div>
        </div>
    );
    return (
        <div className='navbar-container'>
            <div className='flex'>
                <div className="logo cursor-pointer" onClick={() => Navigate("/")} >
                    {/* <PiTiktokLogo color='white' size={50} /> */}
                    <p style={{ textTransform: "uppercase", fontSize: "1.5rem" }}>Plugspace.io</p>
                </div>
                <div className="navBox">

                    <div className='navList' onClick={() => Navigate("/products")}>Products</div>
                    <div className='navList' onClick={() => Navigate("/plans")}>Plans</div>
                    {/* <div className='navList' onClick={() => Navigate("/about")}>About us</div> */}
                    {/* <div className='navList' onClick={() => Navigate("/privacy")}>Privacy</div> */}
                    <div className='navList' onClick={() => Navigate("/howwork")}>How It Works</div>
                    {
                        UserData && UserData?.email ?
                            <>
                                <div className="nav-popover">
                                    <Popover
                                        placement="bottomRight" title={
                                            <div className='popoverHeading'>
                                                <div className='name'>{UserData?.displayName}</div>
                                                <div className="skill">{UserData?.isSuperAdmin ? "SUPER ADMIN" : UserData?.role?.toLocaleUpperCase()}</div>
                                            </div>
                                        } content={content} trigger="click">
                                        <div className='imgBox'>
                                            {
                                                <img src={ImgURL(UserData?.profileImage)} alt="" />
                                            }
                                        </div>
                                    </Popover>
                                </div>
                            </>
                            :

                            <div className="auth">
                                <button onClick={() => Navigate('login')} >Sign In</button>
                                {/* <button onClick={() => Navigate('signup')}>Sign Up</button> */}
                            </div>
                    }
                </div>
            </div>

        </div>
    )
}

export default NavBar