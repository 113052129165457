import React from 'react';

//css
import './Style/AboutUs.scss';

const AboutUs = () => {
  return (
    <div className="aboutus">
      <div className="aboutus-container">
        <h1 className='inline-block bg-gradient-to-r from-[#46A0E3] to-[#5845DF] bg-clip-text p-2 text-6xl font-extrabold text-transparent'>Privacy Policy</h1>
        <h2>Effective Date: <i> August 28, 2024 </i></h2>
        <p>
          At Plugspace.io, we value your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our website and services.</p>
        <h3>What Information Do We Collect?</h3>
        <p>We use your data for the following purposes:</p>
        <ul>
          <li>To provide, maintain, and improve our services.</li>
          <li>To process transactions and manage your account.</li>
          <li>To communicate with you about your account, our services, and promotional offers (if you have opted in).  </li>
          <li>To comply with applicable laws and regulations.</li>
        </ul>

        <h3>How Do We Use Your Data?</h3>
        <p>We collect information that you provide directly to us, as well as information that is automatically collected when you use our services. This information may include: </p>
        <ul>
          <li><b>Basic Personal Information: </b> Name, address, telephone number, email address, company name, billing and payment information.</li>
          <li><b>Website Usage Information:</b> IP address, browser type, device type, pages visited, referring/exit pages, and date/time stamps.</li>
          <li><b>Cookies and Similar Technologies:</b> We use cookies and similar technologies to enhance your browsing experience, personalize content, and analyze website traffic. You can control cookies through your browser settings, but disabling cookies may limit your ability to use certain features of our website.</li>
        </ul>

        <h3>How Do We Protect Your Information?</h3>
        <p>We take the security of your information seriously and implement a variety of security measures to protect it, including:</p>
        <ul>
          <li>Encryption of sensitive data</li>
          <li>Firewalls and intrusion detection systems</li>
          <li>Access controls and restricted employee access</li>
          <li>Regular security audits and assessments</li>
        </ul>

        <h3>Data Sharing</h3>
        <p>We may share your information with trusted third-party service providers who assist us in operating our website, conducting our business, or providing services to you. These third parties are obligated to keep your information confidential and use it only for the purposes for which we disclose it to them. We do not sell or rent your personal information to third parties for their marketing purposes.  </p>

        <h3>How Long Is Your Data Saved?</h3>
        <p>We will retain your personal information for as long as necessary to provide you with our services and fulfill our legal obligations. If you terminate your account, we will delete your personal information within 30 days, unless otherwise required by law.  </p>

        <h3>Your Rights</h3>
        <p>Depending on your location, you may have certain rights regarding your personal information, such as the right to access, correct, delete, or restrict the processing of your data. To exercise these rights, please contact us using the information provided below.  </p>

        <h3>International Data Transfers</h3>
        <p>If we transfer your personal information to a country outside of your jurisdiction, we will take steps to ensure that your information receives an adequate level of protection.</p>

        <h3>Children's Privacy</h3>
        <p>Our services are not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have collected personal information from a child under 13, we will take steps to delete the information as soon as possible.  </p>

        <h3>Data Breach</h3>
        <p>In the event of a data breach, we will notify affected users and relevant authorities (if required) in accordance with applicable laws and regulations.</p>

        <h3>Changes and Updates</h3>
        <p>We may update this Privacy Policy from time to time. We will notify you of any material changes by posting the updated policy on our website or through other communication channels.  </p>

        <h3>Contact Us</h3>
        <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at: </p>

        <strong>Email: support@plugspace.io</strong>
      </div>
    </div>
  );
};

export default AboutUs;
