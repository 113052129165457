import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// ANT-D :
import { Button } from "antd";

// Assets | ICONS :
import { FaArrowRight, FaRegUserCircle, FaFacebook } from 'react-icons/fa';
import { FcGoogle } from 'react-icons/fc';

// APIs :
import useToken from '../Api/useToken'; // Adjust the path as necessary
import { auth } from '../Api/firebase'; // Adjust the path as necessary
import { signInWithPhoneNumber, RecaptchaVerifier } from 'firebase/auth';
import { useSignInWithFacebook, useSignInWithGoogle } from 'react-firebase-hooks/auth';
// Helpers :
import { CheckSocialUserAPI, LoginAPI } from '../Api/auth';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import toast from 'react-hot-toast';
import PhoneInput from 'react-phone-number-input';

// CSS :
import 'react-phone-number-input/style.css'; // Make sure to import the styles for PhoneInput






export default function LoginForm() {
  const Navigate = useNavigate()

  const [formData, setFormData] = useState({
    email: "",
    password: ""
  })
  const [isLoading, setIsLoading] = useState(false);

  const [signInWithGoogle, gUser, gLoading, gError] = useSignInWithGoogle(auth);
  const [signInWithFacebook, fUser, fLoading, fError] = useSignInWithFacebook(auth);

  const [token] = useToken(gUser || fUser);

  const enteringFormData = (event) => {
    let { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    })
  }
  const onSubmit = async (event) => {
    event?.preventDefault();
    setIsLoading(true);

    const res = await LoginAPI({ email: formData.email, password: formData?.password });
    if (res.error != null) {
      toast.error(res.error)
    } else {
      let Token = res.data?.data?.accessToken
      localStorage.setItem('token', Token)
      localStorage.setItem("UserData", JSON.stringify(res.data?.data?.user))
      toast.success(res.data?.message)
      setTimeout(() => {
        window.location.href = "/"
      }, 2000);
    }
    setIsLoading(false);
  };

  // const CheckingSocialUser = async (token) => {
  //   // setIsLoading(true)
  //   let res = await CheckSocialUserAPI(token)
  //   if (res.error != null) {
  //     toast.error(res.error)
  //   } else {
  //     toast.success('Signed in Successfully');
  //     setTimeout(() => {
  //       window.location.href = "/"
  //     }, 2000);
  //   }
  //   // setIsLoading(false)
  // }
  if (token) {
    console.log("======================", token);
    localStorage.setItem('token', token)
    // CheckingSocialUser(token)
    toast.success('Signed in Successfully');
    setTimeout(() => {
      window.location.href = "/"
    }, 2000);
  }

  const handleGoogleSignin = async () => {
    try {
      await signInWithGoogle();
    } catch (error) {
      console.error('Google Sign-In Error:', error);
    }
  };

  const handleFacebookSignin = async () => {
    // await signInWithFacebook();
  };

  // const handleVerifyCode = async () => {
  //   setIsLoading(true);
  //   try {
  //     console.log('Phone number verified successfully.');
  //     toast.success('Phone number verified successfully.');
  //     setIsLoading(false);
  //     //   history.push('/');
  //   } catch (error) {
  //     toast.error(error.message);
  //     setIsLoading(false);
  //     console.error('Error verifying code:', error);
  //   }
  // };

  return (
    <>
      {/* {(gloading || floading) && <Spinner />} */}
      <div className="mb-2 p-2 text-center text-blue-400">
        <FaRegUserCircle size={50} className="inline-block" />
        <div>
          <p className="inline-block bg-gradient-to-r from-[#46A0E3] to-[#5845DF] bg-clip-text p-2 text-lg font-bold text-transparent">
            Sign in to Access Your Profile
          </p>
        </div>
      </div>
      <div className="mb-4 flex justify-evenly">
        <button
          type="button"
          onClick={handleGoogleSignin}
          className="rounded-xl bg-[#EFF6FB] px-6 py-2 hover:opacity-60">
          <FcGoogle size={24} />
        </button>
        <button
          type="button"
          onClick={handleFacebookSignin}
          className="rounded-xl bg-[#EFF6FB] px-6 py-2 hover:opacity-60">
          <FaFacebook size={24} color="#1877EA" />
        </button>
      </div>

      <form onSubmit={onSubmit} className=''>
        <div className="mt-4 flex gap-3 flex-col">
          <div className='phone_signin'>
            <input
              className="w-full rounded-full px-12 py-2 shadow-sm bg-[#EFF6FB] border-none"
              placeholder="Email"
              type='email'
              name='email'
              onChange={enteringFormData}
            />
          </div>

          <div className='phone_signin'>
            <input
              className="w-full rounded-full px-12 py-2 shadow-sm bg-[#EFF6FB] border-none"
              placeholder="Password"
              type='password'
              name='password'
              onChange={enteringFormData}
            />
          </div>
        </div>

        {/* <div className="mt-4" id="recaptcha-container"></div> */}
        <Button
          disabled={isLoading}
          loading={isLoading}
          type="submit"
          onClick={onSubmit}
          className="mx-auto mt-4 flex w-full items-center justify-center gap-2 rounded-full bg-[#1469F3] px-4 py-2 font-medium text-white hover:opacity-80 h-[40px]">
          {' Login'}
          <FaArrowRight className="inline-block" />
        </Button>
      </form>

      <p className="mt-2 flex justify-center gap-1 text-center text-gray-700">
        Don&apos;t have an account?{' '}
        <p className="font-bold text-blue-500 hover:underline cursor-pointer" onClick={() => Navigate('/signup')}>
          Sign Up
        </p>
      </p>
    </>
  )
}
