import React, { useCallback, useEffect, useState } from 'react'
import { useAsyncError, useLocation, useNavigate } from 'react-router-dom'

// ANT-D :
import { Button, Spin, Checkbox } from 'antd'

// APIs :
import { GetAliexpressProductsBykeywordAPI, SaveAliexpressProductsAPI } from '../../Api/ThirdPartyServices'
// Helpers :
import ImgURL from "../../Utils/GenImgUrl"
import toast from 'react-hot-toast'
import InfiniteScroll from "react-infinite-scroller"

// CSS :
import "./Index.scss"



const Index = () => {
    let Location = useLocation();
    let Navigate = useNavigate();

    let Data = Location.state?.data
    let keyword = Location.state?.keyword

    const [allProducts, setAllProducts] = useState(Data)
    const [currentPage, setCurrentPage] = useState(1)
    const [selectedProducts, setSelectedProducts] = useState([])
    const [loading, setLoading] = useState(false)



    const [items, setItems] = useState([]);
    const [nextPage, setNextPage] = useState(true);
    const [fetching, setFetching] = useState(false);

    const loadFunc = useCallback(
        async () => {
            if (fetching) {
                return;
            }

            setFetching(true);

            try {
                let page = currentPage + 1
                await SearchingThirdPartyProducts(page)
                setCurrentPage(page)
            } finally {
                setFetching(false);
            }
        },
        [allProducts, fetching]
    );


    const toggleSelectProduct = async (value, data) => {
        let ID = data?.num_iid
        let FindProduct = selectedProducts.find(product => product?.num_iid == ID)
        let Result;
        if (FindProduct) {
            Result = selectedProducts.filter(product => product?.num_iid != ID)
        } else {
            Result = [...selectedProducts, data]
        }
        setSelectedProducts(Result)
    }

    let SaveProducts = async () => {
        setLoading(true)
        let res = await SaveAliexpressProductsAPI(selectedProducts)
        if (res.error != null) {
            toast.error(res.error)
        } else {
            toast.success(res.data?.message)
            // setTimeout(() => {
            //     Navigate("/")
            // }, 1000);
        }
        setLoading(false)
    }

    const SearchingThirdPartyProducts = async (page) => {
        let res
        res = await GetAliexpressProductsBykeywordAPI({ keyword, page })
        if (res.error != null) {
            toast.error(res.error)
        } else {
            if (res.data?.result?.length >= 1) {
                toast.success(res.data?.message)
                setAllProducts(res.data?.result && res.data?.result?.length >= 1 ? [...allProducts, ...res.data?.result] : allProducts)
            } else {
                toast.success("No more Products")
                setNextPage(false)
            }
        }
    }

    return (
        <>
            <div className='flex flex-col gap-6 p-4 bgColor px-8 py-[5rem] min-h-screen'>
                <div className='flex justify-between items-center'>
                    <h2 className="inline-block bg-gradient-to-r from-[#46A0E3] to-[#5845DF] bg-clip-text p-2 text-6xl font-extrabold text-transparent">
                        PRODUCTS LIST
                    </h2>
                    {selectedProducts.length >= 1 && <Button className='btn' disabled={selectedProducts.length >= 1 ? false : true} onClick={SaveProducts} loading={loading}> Save {selectedProducts.length >= 1 ? <> ({selectedProducts.length}) </> : null} </Button>}
                    {/* <Button className='btn'> Filters </Button> */}
                </div>
                {
                    loading ?
                        <>
                            <div className="progressBox">
                                <Spin />
                            </div>
                        </>
                        :
                        allProducts && allProducts.length >= 1 ?
                            <InfiniteScroll
                                // pageStart={page}
                                loadMore={loadFunc}
                                hasMore={nextPage}
                                loader={<div style={{ display: "flex", justifyContent: "center" }} className="loader" key={0}><Spin /></div>}
                            >
                                <div className="viewProductsListBox">
                                    {
                                        allProducts.map((data, index) => {
                                            return (
                                                <div className="productBox" key={index}>
                                                    <div className="checkbox"><Checkbox checked={selectedProducts.find(product => product?.num_iid == data?.num_iid) ? true : false} onChange={(e) => toggleSelectProduct(e, data)}></Checkbox></div>
                                                    <img src={data?.pic_url} alt="" />
                                                    <p className="title"> {data?.title.split(" ").slice(0, 4).join(" ")} </p>
                                                    <p className="details">{data?.title.length >= 57 ? `${data?.title.slice(0, 57)} ...` : data?.description}</p>
                                                    <div className="flex justify-between items-center px-[.3rem] pr-2">
                                                        <p className="price"> <b className='text-black'> Price : </b> {data?.price}</p>
                                                        {/* <p className="quantity"> <b className='text-black'> QTY : </b> {data?.quantity}</p> */}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </InfiniteScroll>
                            :
                            <>
                                <div className="progressBox">
                                    <p> No Product </p>
                                </div>
                            </>
                }
            </div >
        </>
    )
}

export default Index