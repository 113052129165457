import React, { useState } from 'react'

// Components :
import ListProducts from "./ProductsList/Index"
import AddProduct from "./AddProduct/Index"





const Index = () => {
    const [currentPage, setCurrentPage] = useState("list")

    const closePage = () => {
        setCurrentPage("list")
    }

    return (
        <>
            {
                currentPage == "list" ?
                    <ListProducts setCurrentPage={setCurrentPage} />
                    :
                    <AddProduct closePage={closePage} />
            }
        </>
    )
}

export default Index