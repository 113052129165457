import React, { useState, useMemo } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Slider, { SliderValueLabel } from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { colors } from '@mui/material';

const Pricing = () => {
  const [channels, setChannels] = useState(3);
  const [listings, setListings] = useState(5000);
  const [billingCycle, setBillingCycle] = useState('monthly');

  const calculateSubscriptionFee = useMemo(() => {
    let baseFee = 15; // base fee for calculation
    let channelFee = channels * 10;
    let listingFee = listings / 1000 * 5;
    let totalFee = baseFee + channelFee + listingFee;
    let discount = billingCycle === 'yearly' ? 0.30 * totalFee : 0;
    return (totalFee - discount).toFixed(2);
  }, [channels, listings, billingCycle]);

  const handleChannelChange = (event, newValue) => {
    setChannels(newValue);
  };

  const handleListingsChange = (event, newValue) => {
    setListings(newValue);
  };

  const handleBillingCycleChange = (cycle) => {
    setBillingCycle(cycle);
  };

  const channelMarks = [
    { value: 3, label: '3' },

    { value: 5, label: '5' },
   
    { value: 9, label: '9' },
   
    { value: 11, label: '11' },
   
    { value: 15, label: '15' },
  ];


  const listingsMarks = [
    { value: 1000, label: '1k' },
    { value: 5000, label: '5k' },
    { value: 10000, label: '10k' },
    { value: 25000, label: '25k' },
    { value: 50000, label: '50k' },
    { value: 100000, label: '100k' },
  ];

  const discount = billingCycle === 'yearly'
    ? (0.30 * (50 + channels * 10 + listings / 1000 * 5)).toFixed(2)
    : '0.00';


    const darkTheme = createTheme({
      palette: {
        mode: 'dark',
      

      },
    });

  return (

    <div style={{ backgroundColor: 'rgb(23, 17, 43)',height:'100vh',alignItems:'center',flexDirection:'column', display:'flex',justifyContent:'center'}}>
 <ThemeProvider theme={darkTheme}>
    <Box sx={{ maxWidth:500, margin: '0 auto', paddingTop: '50px',color:'#fff'}} >
      <Typography gutterBottom>
        Number of Channels (Total): {channels}
      </Typography>
      <Slider



        value={channels}
      size='large'
        min={3}
        style={{height:'8px'}}
        max={15}
        step={null}
        onChange={handleChannelChange}
        valueLabelDisplay="auto"
        marks={channelMarks}
        sx={{ width: '100%',display:'flex',justifyContent:'space-between' }}
        aria-label="Number of Channels"
    
      />

      <Typography gutterBottom>
        Number of Products (Total): {listings}
      </Typography>
      <Slider
        value={listings}
        style={{height:'8px'}}
        min={1000}
        max={100000}
        step={null}
        onChange={handleListingsChange}
        sx={{ width: '100%' }}
        valueLabelDisplay="auto"
        marks={listingsMarks}
        aria-label="Number of Listings"
      />

      <Box sx={{ marginTop: '20px', textAlign: 'center' }}>
        <Button
          variant={billingCycle === 'yearly' ? 'contained' : 'outlined'}
          onClick={() => handleBillingCycleChange('yearly')}
          sx={{ marginRight: '10px' }}
        >
          Yearly Billing (30% off)
        </Button>
        <Button
          variant={billingCycle === 'monthly' ? 'contained' : 'outlined'}
          onClick={() => handleBillingCycleChange('monthly')}
        >
          Monthly Billing
        </Button>
      </Box>

      <Box sx={{ marginTop: '20px', textAlign: 'center' }}>
        <Typography>
          Discount: -${discount}
        </Typography>
        <Typography variant="h5">
          Monthly Subscription Fee: ${calculateSubscriptionFee}
        </Typography>
      </Box>
    </Box>
    </ThemeProvider>
    </div>
  );
};

export default Pricing;
