import React from 'react';

//css
import './Style/AboutUs.scss';

const HowWorks = () => {
    return (
        <div className="aboutus">
            <div className="aboutus-container">
                <h1 className='inline-block bg-gradient-to-r from-[#46A0E3] to-[#5845DF] bg-clip-text p-2 text-5xl font-extrabold text-transparent'>How It Works: From Factory to Consumer</h1>
                <p>
                    At Plugspace.io Finds, we've streamlined the entire process from sourcing products at factories to delivering them directly to your customers. Our efficient and transparent workflow ensures that you can focus on growing your business while we handle the logistics. Here's a step-by-step guide to how it all works:
                </p>
                <h3>1. Product Sourcing</h3>
                <p>
                    <strong>Global Supplier Network:</strong> We partner with top-tier factories and suppliers worldwide, including platforms like Alibaba and Pinduoduo. This extensive network provides access to a diverse range of high-quality products at competitive prices.
                </p>
                <p>
                    <strong>Curated Selection:</strong> Our experienced sourcing team meticulously selects products based on market trends, quality standards, and consumer demand. We prioritize items that offer exceptional value and appeal to a wide audience.
                </p>
                <h3>2. Product Listing & Optimization</h3>
                <p>
                    <strong>Customized Listings:</strong> Our team creates compelling product listings tailored to your target marketplaces, including TikTok, Shopify, and others. We focus on crafting engaging descriptions, high-quality images, and SEO-optimized content to enhance visibility and drive sales.
                </p>
                <p>
                    <strong>Real-Time Inventory Management:</strong> We provide tools for real-time inventory tracking, ensuring you always have the latest information on product availability and stock levels.
                </p>
                <h3>3. Order Placement & Fulfillment</h3>
                <p>
                    <strong>Seamless Order Processing:</strong> When a customer places an order on your store, our system instantly processes it. We coordinate with the supplier to confirm product availability and prepare the shipment.
                </p>
                <p>
                    <strong>Quality Control:</strong> We implement rigorous quality checks to ensure that every product meets our high standards before it leaves the factory. This step minimizes the risk of defects and ensures customer satisfaction.
                </p>
                <h3>4. Shipping & Logistics</h3>
                <p>
                    <strong>Global Shipping Solutions:</strong> We offer a variety of shipping options, from standard to express delivery, to cater to different customer needs. Our logistics partners are selected for their reliability and efficiency, ensuring timely delivery.
                </p>
                <p>
                    <strong>Order Tracking:</strong> Customers can track their orders in real-time, providing transparency and peace of mind. We also offer support for handling customs and duties, ensuring a smooth delivery experience.
                </p>
                <h3>5. Customer Delivery & Support</h3>
                <p>
                    <strong>Final Delivery:</strong> Once the product arrives at its destination, it is delivered directly to the customer. Our streamlined process ensures that products reach customers quickly and in perfect condition.
                </p>
                <p>
                    <strong>Customer Service:</strong> Our dedicated support team is available to assist with any inquiries or issues. Whether it's handling returns, addressing concerns, or providing additional information, we are committed to delivering exceptional customer service.
                </p>
                <h3>Contact Us</h3>
                <p>support@plugspace.io</p>
                <p>339-333-6548</p>
                <p>539 W. Commerce St Suite 6614, Dallas TX 75208, United States</p>
            </div>
        </div>
    );
};

export default HowWorks;
