import React from "react";
// import LoginForm from "../auth/FireBaseLogin";
import LoginForm from "../auth/Login";
import Footer from "./Footer";






export default function Login() {
  return (
    <>
      <div className="login-container">
        <h1>
          <title>Plugspace.io | Signin</title>
        </h1>
        <div className="flex flex-col justify-evenly overflow-hidden lg:flex-row">
          <div className="ml-4">
            <h2 className="mt-20 inline-block bg-gradient-to-r from-[#46A0E3] to-[#5845DF] bg-clip-text p-2 text-6xl font-extrabold text-transparent lg:mt-60">
              Hey,
              <br />
              welcome back!
            </h2>
            <p className="p-2 font-medium text-white">
              Sign in to access your account.
            </p>
          </div>
          <div className="mx-8 mt-8 min-h-[550px] rounded-3xl bg-white p-8 lg:mt-40 mb-[30px]" style={{ backgroundColor: 'white', }}>
            <LoginForm />
          </div>
        </div>
      </div>
    </>
  );
}