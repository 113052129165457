import React, { useEffect, useState } from 'react'

// ANT-D :
import { Checkbox, Image, Select, Table, Tooltip } from 'antd';

// Assets | ICONS :
import { DeleteFilled } from '@ant-design/icons';

// APIs :
import { DeleteUserAPI, GetAllUserAPI, ToggleAdminAPI } from '../../../Api/admin';
// Helpers :
import ImgURL from "../../../Utils/GenImgUrl"
import toast from "react-hot-toast"






function UserList() {

    const [Users, setAllUsers] = useState([])
    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)


    const TogglingAdmin = async (id) => {
        setLoading(true)
        let res = await ToggleAdminAPI(id)
        if (res.error != null) {
            toast.error(res.error)
        } else {
            toast.success(res.data?.message)
            setRefresh(!refresh)
        }
        setLoading(false)
    }
    const DeleteUser = async (id) => {
        setLoading(true)
        let res = await DeleteUserAPI(id)
        if (res.error != null) {
            toast.error(res.error)
        } else {
            toast.success(res.data?.message)
            setRefresh(!refresh)
        }
        setLoading(false)
    }

    const columns = [
        {
            title: 'Image',
            render: (_, data) => <Image width={70} src={ImgURL(data?.profileImage)} style={{ borderRadius: ".3rem", overflow: "hidden" }} />
        },
        {
            title: 'User Name',
            dataIndex: 'displayName',
            key: 'displayName',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
        },

        {
            title: 'Actions',
            dataIndex: 'action',
            key: 'action',
            render: (_, data) => {
                return (
                    <div style={{ display: 'flex', gap: '1rem', }}>
                        <Tooltip placement="bottom" title={'Make Admin'} >
                            <Checkbox onClick={() => TogglingAdmin(data?._id)} checked={data?.role == "admin" ? true : false} />
                        </Tooltip>
                        <DeleteFilled onClick={() => DeleteUser(data?._id)} style={{ cursor: "pointer", color: "red", fontSize: "1rem" }} />
                    </div>
                )
            }
        },

    ];

    const GettingAllUsers = async () => {
        setLoading(true)
        let res = await GetAllUserAPI()
        if (res.error != null) {
            toast.error(res.error)
        } else {
            setAllUsers(res.data?.result || [])
        }
        setLoading(false)
    }
    useEffect(() => {
        GettingAllUsers()
    }, [refresh])

    return (
        <>
            <div className='flex flex-col gap-6 p-4 bgColor'>
                <div className='flex justify-between items-center'>
                    <h2 className="inline-block bg-gradient-to-r from-[#46A0E3] to-[#5845DF] bg-clip-text p-2 text-6xl font-extrabold text-transparent">
                        USERS LIST
                    </h2>
                </div>
                <div className="CustomTable">
                    <Table loading={loading} columns={columns} dataSource={Users} />
                </div>
            </div>
        </>
    )
}

export default UserList