import React from 'react'
import './Style/plans.scss'
import tiktok from '../Assets/tiktoklogo.png'
import ebay from '../Assets/ebay.png'



function Plans() {
  return (
    <div className='p-container'>
<div className="plans">
<div style={{paddingBottom:'19px'}}>
<div className="header"><div><img src={tiktok} alt="" /></div> <div className="heaing"><h1>Basic Plans</h1></div> <div><img src={ebay} alt="" /></div></div>
    <div className="cards">
        <div className="card">
<h4>Monthly</h4>
<span>$49.99</span>
<p>1000 Products <br /> 3 Channel</p>
        </div>
        <div className="card">
<h4>Per Month <span style={{color:'red',fontSize:'17px'}}>Hot</span></h4>
<span>$35</span>
<p>1000 Products <br /> 3 Channel</p>
        </div>
        <div className="card">
<h4>Monthly 
</h4>
<span>$49.99</span>
<p>1000 Products <br /> 3 Channel</p>
        </div>
        <div className="card">
<h4>PerMonth</h4>
<span>$75</span>
<p>2k Products <br /> 5 Channel</p>
        </div>
        
    </div>
    </div>
    <div style={{paddingBottom:'19px'}}>
    <div className="header"><div><img src={tiktok} alt="" /></div> <div className="heaing"><h1>Standard Plans</h1></div> <div><img src={ebay} alt="" /></div></div>
    <div className="cards">
        <div className="card">
<h4>Monthly</h4>
<span>150$</span>
<p>10K Products <br /> 9 Channel</p>
        </div>
        <div className="card">
<h4>Per Month </h4>
<span>$120</span>
<p>10k Products <br /> 9 Channel</p>
        </div>
        <div className="card">
<h4>Monthly 
</h4>
<span>$210</span>
<p>25k Products <br /> 11 Channel</p>
        </div>
        <div className="card">
<h4>PerMonth</h4>
<span>$175</span>
<p>25k Products <br /> 11 Channel</p>
        </div>
       
    </div>
    </div>
    <div style={{paddingBottom:'35px'}}>
    <div className="header"><div><img src={tiktok} alt="" /></div> <div className="heaing"><h1>Altera Mega Store</h1></div> <div><img src={ebay} alt="" /></div></div>
    <div className="cards">
        <div className="card">
<h4>Monthly</h4>
<span>$350</span>
<p>50k Products <br /> 15 Channel</p>
        </div>
        <div className="card">
<h4>Per Month </h4>
<span>$260</span>
<p>50k Products <br /> 15 Channel</p>
        </div>
        <div className="card">
<h4>Monthly 
</h4>
<span>$420</span>
<p>100k Products <br /> 15 Channel</p>
        </div>
        <div className="card">
<h4>PerMonth</h4>
<span>$350</span>
<p>200k Products <br /> 15 Channel</p>
        </div>
       
    </div>
    </div>
</div>

    </div>
  )
}

export default Plans