import React, { useEffect, useState } from 'react'

// ANT-D :
import { Button, Upload } from 'antd'
import ImgCrop from 'antd-img-crop';

// APIs :
import { AddProductAPI } from '../../../../Api/products';

// Helper :
import toast from 'react-hot-toast';

// CSS :
import "./AddProduct.scss"




const Index = ({ closePage }) => {

    const [formData, setFormData] = useState({
        title: "",
        price: "",
        originalPrice: "",
        description: "",
        brand: "",
        size: "",
        weight: "",
        quantity: "",
    })
    const [fileList, setFileList] = useState([]);
    const [loading, setLoading] = useState(false)
    const [reload, setReload] = useState(false)


    const enteringFormData = (event) => {
        let { name, value } = event.target;

        setFormData({
            ...formData,
            [name]: value
        })
    }

    const enteringFile = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    }
    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const imgWindow = window.open(src);
        imgWindow?.document.write(`<img src="${src}" alt="preview" />`);
    };

    const AddProduct = async () => {
        setLoading(true)
        let Payload = new FormData()
        Object.keys(formData).map(key => {
            Payload.append(key, formData[key])
        })
        fileList.map(file => {
            Payload.append("file", file.originFileObj)
        })

        let res = await AddProductAPI(Payload)
        if (res.error != null) {
            toast.error(res.error)
        } else {
            toast.success(res.data.message)
            closePage()
        }
        setLoading(false)
    }

    return (
        <>
            <div className='AddProductContainer' style={{ padding: '0px' }}>

                <div className="title">
                    <h2 className="inline-block bg-gradient-to-r from-[#46A0E3] to-[#5845DF] bg-clip-text p-2 text-6xl font-extrabold text-transparent">
                        Add Product
                    </h2>
                </div>
                <div className="form">
                    <div className='flex flex-col gap-8 bg-white p-6 rounded-2xl max-w-[600px] m-auto'>
                        <div className="m-auto">
                            {/* <ImgUploader  /> */}
                            <ImgCrop rotationSlider>
                                <Upload
                                    listType="picture-card"
                                    fileList={fileList}
                                    multiple={true}
                                    onChange={enteringFile}
                                    onPreview={onPreview}
                                    className="custom-upload" // Apply the custom class
                                >
                                    {
                                        formData?.displayName ?
                                            <img alt="" style={{ width: "98%", height: "98%", objectFit: "cover", borderRadius: ".4rem" }} />
                                            :
                                            fileList.length < 10 && '+ Upload'
                                    }
                                </Upload>
                            </ImgCrop>
                        </div>

                        <div className='flex flex-col gap-2'>
                            <div className="flex gap-2 items-center">
                                <div className="flex flex-col gap-1 w-full">
                                    <p className='ml-3'>Product Name </p>
                                    <input
                                        className="input-field mt-4 w-full rounded-2xl border-0 px-3 py-4"
                                        id="name"
                                        type="text"
                                        name='title'
                                        value={formData?.title}
                                        onChange={enteringFormData}
                                        placeholder="Name"
                                    />
                                </div>
                                <div className="flex flex-col gap-1 w-full">
                                    <p className='ml-3'>Brand </p>
                                    <input
                                        className="input-field mt-4 w-full rounded-2xl border-0 px-3 py-4"
                                        id="name"
                                        type="text"
                                        name='brand'
                                        value={formData?.brand}
                                        onChange={enteringFormData}
                                        placeholder="quantity"
                                    />
                                </div>
                            </div>

                            <div className="flex gap-2 items-center">
                                <div className="flex flex-col gap-1 w-full">
                                    <p className='ml-3'> Price </p>
                                    <input
                                        className="input-field mt-4 w-full rounded-2xl border-0 px-3 py-4"
                                        id="name"
                                        type="text"
                                        name='price'
                                        value={formData?.price}
                                        onChange={enteringFormData}
                                        placeholder="Sales Price"
                                    />
                                </div>

                                <div className="flex flex-col gap-1 w-full">
                                    <p className='ml-3'> Original Price </p>
                                    <input
                                        className="input-field mt-4 w-full rounded-2xl border-0 px-3 py-4"
                                        id="name"
                                        type="text"
                                        name='originalPrice'
                                        value={formData?.originalPrice}
                                        onChange={enteringFormData}
                                        placeholder="OriginalPrice"
                                    />
                                </div>
                            </div>
                            <div className="flex gap-2 items-center">
                                <div className="flex flex-col gap-1 w-full">
                                    <p className='ml-3'> Size </p>
                                    <input
                                        className="input-field mt-4 w-full rounded-2xl border-0 px-3 py-4"
                                        id="name"
                                        type="text"
                                        name='size'
                                        value={formData?.size}
                                        onChange={enteringFormData}
                                        placeholder="Size"
                                    />
                                </div>

                                <div className="flex flex-col gap-1 w-full">
                                    <p className='ml-3'> Weight </p>
                                    <input
                                        className="input-field mt-4 w-full rounded-2xl border-0 px-3 py-4"
                                        id="name"
                                        type="text"
                                        name='weight'
                                        value={formData?.weight}
                                        onChange={enteringFormData}
                                        placeholder="Weight"
                                    />
                                </div>
                            </div>
                            <div className="flex gap-2 items-center">
                                <div className="flex flex-col gap-1 w-full">
                                    <p className='ml-3'> Quantity </p>
                                    <input
                                        className="input-field mt-4 w-full rounded-2xl border-0 px-3 py-4"
                                        id="name"
                                        type="number"
                                        name='quantity'
                                        value={formData?.quantity}
                                        onChange={enteringFormData}
                                        placeholder="Quantity"
                                    />
                                </div>

                                <div className="flex flex-col gap-1 w-full">
                                    <p className='ml-3'> Description </p>
                                    <input
                                        className="input-field mt-4 w-full rounded-2xl border-0 px-3 py-4"
                                        id="name"
                                        type="text"
                                        name='description'
                                        value={formData?.description}
                                        onChange={enteringFormData}
                                        placeholder="Details"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='btnn flex gap-2'>
                            <Button
                                loading={loading}
                                type="submit"
                                onClick={AddProduct}
                                className="mx-auto mt-4 flex w-full items-center justify-center gap-2 rounded-full bg-[#1469F3] px-4 py-2 font-medium text-white hover:opacity-80 h-[40px]">

                                <label htmlFor="">Save</label>
                            </Button>

                            <Button
                                onClick={closePage}
                                type="submit"
                                className="mx-auto mt-4 flex w-full items-center justify-center gap-2 rounded-full bg-[#f36464d9] px-4 py-2 font-medium text-white hover:opacity-80 h-[40px]">
                                <label htmlFor="">Cancel</label>
                            </Button>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default Index