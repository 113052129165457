import React, { useEffect, useState } from 'react'

// ANT-D :
import { Image, Table, Button, Tooltip, Spin } from "antd";

// Assets | ICONS :
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { MdDashboard, MdTableChart } from "react-icons/md";

// APIs :
import { DeleteProductAPI } from '../../../../Api/products';
import { DeleteSaved1688ProductAPI, GetSaved1688ProductsAPI, GetSavedAliexpressProductsAPI } from '../../../../Api/ThirdPartyServices';
// Helpers :
import toast from 'react-hot-toast';
import ImgURL from "../../../../Utils/GenImgUrl";

// CSS :
import "./Index.scss";





const Index = ({ setCurrentPage, setSelectedId }) => {

    const [AllProducts, setAllProducts] = useState([])
    const [view, setView] = useState("table")
    const [refresh, setRefresh] = useState(false)
    const [loading, setLoading] = useState(false)

    const columns = [
        {
            title: 'Image',
            render: (_, data) => <Image width={70} src={data?.imageUrl} style={{ borderRadius: ".3rem", overflow: "hidden" }} />
        },
        {
            title: 'Name',
            dataIndex: 'title',
            key: 'title',
            render: (_, data) => data?.subject
            // render: (_, data) => data?.title?.split(" ").slice(0, 4).join(" ")
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            width: "400px",
            render: (_, data) => data?.subjectTrans
        },
        // {
        //     title: 'Quantity',
        //     dataIndex: 'quantity',
        //     key: 'quantity',
        // },
        {
            title: 'Original Price',
            dataIndex: 'price',
            key: 'price',
            render: (_, data) => data?.priceInfo?.price
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            key: 'action',
            render: (_, data) => {
                return (
                    <div style={{ display: 'flex', gap: '1rem', }}>
                        <Tooltip title="Edit">
                            <EditOutlined onClick={() => EditProduct(data?._id)} style={{ cursor: "pointer", fontSize: "1rem" }} />
                        </Tooltip>
                        <Tooltip title="Delete">
                            <DeleteOutlined onClick={() => DeleteProduct(data?._id)} style={{ cursor: "pointer", color: "red", fontSize: "1rem" }} />
                        </Tooltip>
                    </div>
                )
            }
        },
    ];

    let EditProduct = (id) => {
        setSelectedId(id)
        setCurrentPage("edit")
    }

    let DeleteProduct = async (id) => {
        setLoading(true)
        let res = await DeleteSaved1688ProductAPI(id)
        if (res.error != null) {
            toast.error(res.error)
        } else {
            toast.success(res.data?.message)
            setRefresh(!refresh)
        }
        setLoading(false)
    }

    let GettingAllProducts = async () => {
        setLoading(true)
        let res = await GetSaved1688ProductsAPI()
        if (res.error != null) {
            toast.error(res.error)
        } else {
            setAllProducts(res.data?.result || [])
        }
        setLoading(false)
    }
    useEffect(() => {
        GettingAllProducts()
    }, [refresh])

    return (
        <>
            <div className='flex flex-col gap-6 p-4 bgColor overflow-y-scroll h-full'>
                <div className='flex justify-between items-center'>
                    <h2 className="inline-block bg-gradient-to-r from-[#46A0E3] to-[#5845DF] bg-clip-text p-2 text-6xl font-extrabold text-transparent">
                        PRODUCTS LIST
                    </h2>
                    <div style={{ display: "flex", alignItems: "center", gap: "1.5rem", fontSize: "2rem" }}>
                        <Tooltip title="Toggle View"> {view == "table" ? <MdDashboard style={{ cursor: "pointer" }} onClick={() => setView("card")} /> : <MdTableChart style={{ cursor: "pointer" }} onClick={() => setView("table")} />}  </Tooltip>
                        {/* <Button className='btn' onClick={() => setCurrentPage("add")} > ADD PRODUCT </Button> */}
                    </div>
                </div>
                <div className="CustomTable">
                    {
                        view == "table" ?
                            <Table loading={loading} columns={columns} dataSource={AllProducts} />
                            :
                            loading ?
                                <>
                                    <div className="progressBox">
                                        <Spin />
                                    </div>
                                </>
                                :
                                AllProducts && AllProducts.length >= 1 ?
                                    <div className="viewProductsListBox">
                                        {
                                            AllProducts.map((data, index) => {
                                                return (
                                                    <div className="productBox" key={index}>
                                                        <div className="checkbox bottomBox" ><DeleteOutlined onClick={() => DeleteProduct(data?._id)} style={{ cursor: "pointer", color: "red", fontSize: "1rem" }} /></div>
                                                        <div className="checkbox bottomBoxEdit" ><EditOutlined onClick={() => EditProduct(data?._id)} style={{ cursor: "pointer", fontSize: "1rem" }} /></div>
                                                        <Image src={data?.imageUrl} style={{ borderRadius: ".3rem", overflow: "hidden" }} />
                                                        <p className="title"> {data?.subject.split(" ").slice(0, 3).join(" ")} </p>
                                                        <p className="details">{data?.subjectTrans?.length >= 57 ? `${data?.subjectTrans.slice(0, 57)} ...` : data?.description?.length >= 57 ? `${data?.description.slice(0, 57)} ...` : data?.description}</p>
                                                        <div className="flex justify-between items-center px-[.3rem] pr-2">
                                                            <p className="price"> <b className='text-black'> Price : </b> {data?.priceInfo?.price}</p>
                                                            {/* <p className="quantity"> <b className='text-black'> QTY : </b> {data?.quantity}</p> */}
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    :
                                    <>
                                        <div className="progressBox">
                                            <p> No Product </p>
                                        </div>
                                    </>
                    }
                </div>
            </div>
        </>
    )
}

export default Index