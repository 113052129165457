import Axios from "../AxiosInstance"


const GetAliexpressProductsBykeywordAPI = async ({ keyword, page }) => {
    let resolve = {
        data: null,
        error: null
    }
    try {
        let res = await Axios({
            url: `/products/aliexpress?keyword=${keyword}&page=${page}`,
            method: "GET",
        })
        resolve.data = res.data
    } catch (err) {
        if (err && err?.response) {
            resolve.error = err?.response?.message || err?.response?.data?.message
        } else {
            resolve.error = "SomthingWent wrong"
        }
    }
    return resolve
}
const GetSavedAliexpressProductsAPI = async () => {
    let resolve = {
        data: null,
        error: null
    }
    try {
        let res = await Axios({
            url: `/products/aliexpress/saved`,
            method: "GET",
        })
        resolve.data = res.data
    } catch (err) {
        if (err && err?.response) {
            resolve.error = err?.response?.message || err?.response?.data?.message
        } else {
            resolve.error = "SomthingWent wrong"
        }
    }
    return resolve
}
const GetSpecificSavedAliexpressProductAPI = async (id) => {
    let resolve = {
        data: null,
        error: null
    }
    try {
        let res = await Axios({
            url: `/products/aliexpress/saved/${id}`,
            method: "GET",
        })
        resolve.data = res.data
    } catch (err) {
        if (err && err?.response) {
            resolve.error = err?.response?.message || err?.response?.data?.message
        } else {
            resolve.error = "SomthingWent wrong"
        }
    }
    return resolve
}
const SaveAliexpressProductsAPI = async (data) => {
    let resolve = {
        data: null,
        error: null
    }
    try {
        let res = await Axios({
            url: `/products/aliexpress`,
            method: "POST",
            data
        })
        resolve.data = res.data
    } catch (err) {
        if (err && err?.response) {
            resolve.error = err?.response?.message || err?.response?.data?.message
        } else {
            resolve.error = "SomthingWent wrong"
        }
    }
    return resolve
}
const DeleteSavedAliexpressProductAPI = async (id) => {
    let resolve = {
        data: null,
        error: null
    }
    try {
        let res = await Axios({
            url: `/products/aliexpress/?id=${id}`,
            method: "DELETE",
        })
        resolve.data = res.data
    } catch (err) {
        if (err && err?.response) {
            resolve.error = err?.response?.message || err?.response?.data?.message
        } else {
            resolve.error = "SomthingWent wrong"
        }
    }
    return resolve
}




const Get1688ProductsByImageAPI = async (data) => {
    let resolve = {
        data: null,
        error: null
    }
    try {
        let res = await Axios({
            url: `/products/1688`,
            method: "POST",
            data
        })
        resolve.data = res.data
    } catch (err) {
        if (err && err?.response) {
            resolve.error = err?.response?.message || err?.response?.data?.message
        } else {
            resolve.error = "SomthingWent wrong"
        }
    }
    return resolve
}
const GetSaved1688ProductsAPI = async () => {
    let resolve = {
        data: null,
        error: null
    }
    try {
        let res = await Axios({
            url: `/products/1688/saved`,
            method: "GET",
        })
        resolve.data = res.data
    } catch (err) {
        if (err && err?.response) {
            resolve.error = err?.response?.message || err?.response?.data?.message
        } else {
            resolve.error = "SomthingWent wrong"
        }
    }
    return resolve
}
const GetSpecificSaved1688ProductAPI = async (id) => {
    let resolve = {
        data: null,
        error: null
    }
    try {
        let res = await Axios({
            url: `/products/1688/saved/${id}`,
            method: "GET",
        })
        resolve.data = res.data
    } catch (err) {
        if (err && err?.response) {
            resolve.error = err?.response?.message || err?.response?.data?.message
        } else {
            resolve.error = "SomthingWent wrong"
        }
    }
    return resolve
}
const Save1688ProductsAPI = async (data) => {
    let resolve = {
        data: null,
        error: null
    }
    try {
        let res = await Axios({
            url: `/products/1688/save`,
            method: "POST",
            data
        })
        resolve.data = res.data
    } catch (err) {
        if (err && err?.response) {
            resolve.error = err?.response?.message || err?.response?.data?.message
        } else {
            resolve.error = "SomthingWent wrong"
        }
    }
    return resolve
}
const DeleteSaved1688ProductAPI = async (id) => {
    let resolve = {
        data: null,
        error: null
    }
    try {
        let res = await Axios({
            url: `/products/1688/?id=${id}`,
            method: "DELETE",
        })
        resolve.data = res.data
    } catch (err) {
        if (err && err?.response) {
            resolve.error = err?.response?.message || err?.response?.data?.message
        } else {
            resolve.error = "SomthingWent wrong"
        }
    }
    return resolve
}



export { GetAliexpressProductsBykeywordAPI, SaveAliexpressProductsAPI, Get1688ProductsByImageAPI, GetSavedAliexpressProductsAPI, GetSaved1688ProductsAPI, Save1688ProductsAPI, GetSpecificSaved1688ProductAPI, GetSpecificSavedAliexpressProductAPI, DeleteSavedAliexpressProductAPI, DeleteSaved1688ProductAPI }