import React, { useEffect, useState, useRef } from 'react'
import { useAsyncError, useNavigate } from "react-router-dom"
// ANT-D :
import { Button, Input } from 'antd'

// Assets | ICONS :
import { FaCamera } from "react-icons/fa";
import InfoGif from "../Assets/info.gif"

// Componets :
import Footer from './Footer'

// APIs :
import { GetAliexpressProductsBykeywordAPI, Get1688ProductsByImageAPI } from '../Api/ThirdPartyServices'
// Helpers :
import { toast } from "react-hot-toast"
import imageCompression from 'browser-image-compression';

// CSS :
import './Style/Home.scss'





function Home() {
  const InputRef = useRef(null)
  let Navigate = useNavigate()

  const [keyword, setkeyword] = useState("")
  const [AllProducts, setAllProducts] = useState([])
  const [loading, setLoading] = useState(false)
  const [file, setFile] = useState(null)

  const handleUpload = () => {
    InputRef.current.click()
  }
  const uploadingFile = async (event) => {
    let ImageFile = event?.target?.files[0]
    if (ImageFile.size > 100 * 1024) { // 100KB in bytes
      const options = {
        maxSizeMB: 0.1, // Set the maximum size to 0.1MB (100KB)
        maxWidthOrHeight: 1024, // Optional, maintain a reasonable dimension
        useWebWorker: true,
      };
      const compressedFile = await imageCompression(ImageFile, options);
      setFile(compressedFile)
      // setkeyword(`${ImageFile.name} - COMPRESSED`)
      setkeyword(`Wait AI is ranking your products .......`)
      // toast.error('File size must be less than 100KB.');
    } else {
      setFile(ImageFile)
      setkeyword(ImageFile.name)
    }
  }


  const SearchingThirdPartyProducts = async () => {
    setLoading(true)
    let res
    if (file) {
      const ImgData = new FormData()
      ImgData.append("file", file)
      res = await Get1688ProductsByImageAPI(ImgData)
    } else {
      res = await GetAliexpressProductsBykeywordAPI({ keyword })
    }
    if (res.error != null) {
      toast.error(res.error)
    } else {
      if (res.data?.result && res.data?.result.length >= 1) {
        toast.success(res.data?.message)
        setAllProducts(res.data?.result || [])
        if (file) {
          let ImageID = res.data?.ImageID
          Navigate("/view1688Products", { state: { data: res.data?.result, ImageID } })
        } else {
          Navigate("/viewAliExpressProducts", { state: { data: res.data?.result, keyword } })
        }
      } else {
        toast.success("Products Not Found")
      }
    }
    setLoading(false)
  }
  return (
    <>

      <div className='home-container'>
        <div className='home-box'>
          <h1>All In One Click</h1>
          <p>Taoboa , Alibaba , 1688 , Aliexpress , Amazon , Temu , Shein , Ebay</p>
          <div className='flex gap-2 bg-white justify-center items-center mb-3 w-full px-2 inputBox'>
            <Input className='m-0' placeholder='Search' value={keyword} onChange={(e) => { setkeyword(e.target.value); setFile(null); }} ></Input>
            <FaCamera className='icon' onClick={handleUpload} />
            <input hidden ref={InputRef} type="file" id="imageUpload" accept="image/*" onChange={uploadingFile} />
          </div>
          <Button loading={loading} onClick={SearchingThirdPartyProducts} >Search</Button>
          <div className='gif'>
            <img src={InfoGif} alt="" />
          </div>
        </div>
      </div>
    </>
  )
}

export default Home