import React, { useEffect, useState } from 'react'

// ANT-D :
import { Button, Spin } from 'antd'

// APIs :
import { GetAllProductsAPI } from '../../Api/products'
// Helpers :
import ImgURL from "../../Utils/GenImgUrl"
import toast from 'react-hot-toast'

// CSS :
import "./Index.scss"



const Index = () => {

    const [allProducts, setAllProducts] = useState([])
    const [loading, setLoading] = useState(false)

    let GettingProducts = async () => {
        setLoading(true)
        let res = await GetAllProductsAPI()
        if (res.error != null) {
            toast.error(res.error)
        } else {
            setAllProducts(res.data?.result || [])
        }
        setLoading(false)
    }
    useEffect(() => {
        GettingProducts()
    }, [])

    return (
        <>
            <div className='flex flex-col gap-6 p-4 bgColor px-8 py-[5rem] min-h-screen h-[100vh]'>
                <div className='flex justify-between items-center'>
                    <h2 className="inline-block bg-gradient-to-r from-[#46A0E3] to-[#5845DF] bg-clip-text p-2 text-6xl font-extrabold text-transparent">
                        PRODUCTS LIST
                    </h2>
                    <Button className='btn'> Filters </Button>
                </div>
                {
                    loading ?
                        <>
                            <div className="progressBox">
                                <Spin />
                            </div>
                        </>
                        :

                        allProducts && allProducts.length >= 1 ?
                            <div className="productsListBox">
                                {
                                    allProducts.map((data, index) => {
                                        return (
                                            <div className="productBox" key={index}>
                                                <img src={ImgURL(data?.images[0])} alt="" />
                                                <p className="title"> {data?.title} </p>
                                                <p className="details">{data?.description.length >= 57 ? `${data?.description.slice(0, 57)} ...` : data?.description}</p>
                                                <div className="flex justify-between items-center px-[.3rem] pr-2">
                                                    <p className="price"> <b className='text-black'> Price : </b> {data?.price}</p>
                                                    <p className="quantity"> <b className='text-black'> QTY : </b> {data?.quantity}</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            :
                            <>
                                <div className="progressBox">
                                    <p> No Product </p>
                                </div>
                            </>
                }
            </div>
        </>
    )
}

export default Index