import { AppstoreAddOutlined, DashOutlined, DashboardOutlined, PoweroffOutlined, ProductOutlined, UnorderedListOutlined, UserOutlined } from "@ant-design/icons";
import { ConfigProvider, Menu, theme, Layout } from "antd";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import App from "../../App";
import UserList from "./Users/UserList";
import Products from "./Products/index"
import AliProducts from "./AliExpressProducts/index"
import Products1688 from "./1688Products/index"
import AddProducts from "./Products/AddProduct/Index"
import { useState } from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { PiTiktokLogo } from "react-icons/pi";
import "./Dashboard.scss"




let { Sider } = Layout;
function Dashboard() {
    const Navigate = useNavigate();

    const [collapsed, setCollapsed] = useState(false)

    return (

        <div style={{ display: 'flex', flexDirection: 'row', height: '100vh', backgroundColor: 'rgb(23, 17, 43)' }} className="DashboardContainer">
            <ConfigProvider
                theme={{ algorithm: theme.darkAlgorithm }}
            >
                <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)} width="300" className="sider" trigger={<> <div className="trig">{collapsed ? <FaAngleRight /> : <FaAngleLeft />}</div> </>}>
                    <div className="logoBox" style={collapsed ? { fontSize: "2rem", justifyContent: "flex-start", paddingLeft: "1.5rem" } : {}} onClick={() => Navigate("/")}>
                        {/* <img style={collapsed ? { width: "60px" } : {}} src={Logo} alt="ERROR" /> */}
                        <PiTiktokLogo />
                    </div>
                    <Menu
                        mode="inline"
                        style={{ minWidth: "250px" }}
                        onClick={({ key }) => {
                            if (key === 'signout') {
                                //signout functionality
                            }
                            else {
                                Navigate(key)
                            }
                        }}

                        items={[
                            { label: "Users List", key: 'userlist', icon: <UnorderedListOutlined /> },
                            { label: "Products", key: "products", icon: <ProductOutlined /> },
                            { label: "Search by Text Products", key: "aliexpressproducts", icon: <ProductOutlined /> },
                            { label: "search by Image Products", key: "1688products", icon: <ProductOutlined /> },
                            // { label: "Ad Products", key: "addproducts",icon:<AppstoreAddOutlined /> },

                            { label: "Signout", key: 'signout', icon: <PoweroffOutlined />, danger: 'true' },
                        ]}
                    >

                    </Menu>
                </Sider>
                <div className="w-full">
                    <Routes>
                        <Route path='userlist' element={<UserList />} />
                        <Route path='products' element={<Products />} />
                        <Route path='aliexpressproducts' element={<AliProducts />} />
                        <Route path='1688products' element={<Products1688 />} />
                        <Route path='addproducts' element={<AddProducts />} />
                    </Routes>
                </div>
            </ConfigProvider>
        </div>

    );

}
export default Dashboard;


